<template>
    <div>
        <ContactHero />
    </div>
</template>

<script>
import ContactHero from '@/components/ContactHero.vue';

export default {
    components: { ContactHero }
}
</script>

<style scoped></style>