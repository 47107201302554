<template>
    <div class="">
        <PrivacyComponent />
        <FooterComponent />
    </div>
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import ModulesComponent from '@/components/ModulesComponent.vue';
import PrivacyComponent from '@/components/PrivacyComponent.vue';
export default {
    name: "FaqPage",
    components: {
        FooterComponent,
        ModulesComponent,
        PrivacyComponent
    }
}
</script>

<style scoped></style>