<template>
    <div>
        <AdministrationComponent />
    </div>
</template>

<script>
import AdministrationComponent from '@/components/AdministrationComponent.vue';

export default {
    components: { AdministrationComponent }
}
</script>

<style scoped></style>