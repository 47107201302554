<template>
    <div>
        <div class="content_container">
            <NavBar />
            <div>
                <h1 class="heroHeader primaryColor">Privacy Policy</h1>
                <p>Effective Date: September 08, 2023</p>
            </div>
            <div class="privacy_container">
                <div class="info_container">
                    <h4>1. Introduction</h4>
                    <p>Welcome to TeddyEd, the comprehensive school management system created by Kuadratik Inc. This privacy
                        policy is designed to help you understand how we collect, use, disclose, and safeguard your personal
                        information when you use the TeddyEd application (mobile and web).</p>
                </div>
                <div class="info_container">
                    <h4>2. Information We Collect</h4>
                    <p>We may collect the following types of information when you use the TeddyEd mobile app:</p>
                    <ul>
                        <li>

                            Personal Information: This may include your name, email address, phone number, and any other
                            information you provide when you register for an account or use our app.
                        </li>
                        <li>
                            Student Information: If you are a parent or guardian using the app to interact with a school, we
                            may collect information about your child, such as their name, class schedule and attendance
                            records.

                        </li>
                        <li>
                            Usage Information: We may collect information about how you use the app, including your
                            interactions with features, content, and communications.

                        </li>

                    </ul>
                </div>
                <div class="info_container">
                    <h4>3. How We Use Your Information</h4>
                    <p>We use the information we collect for the following purposes:</p>
                    <ul>
                        <li>To Provide Services: We use your personal and student information to provide you with access to
                            the TeddyEd app and its modular features.</li>
                        <li>
                            To Improve Our Services: We may use usage information to analyze app usage patterns and improve
                            the functionality and user experience of TeddyEd.
                        </li>
                    </ul>
                </div>
                <div class="info_container">
                    <h4>4. Disclosure of Your Information</h4>
                    <p>We may share your information with the following parties:</p>
                    <ul>
                        <li>Schools: We may share your inputs with your associated TeddyEd schools to facilitate
                            communication and student management.</li>

                        <li>Service Providers: We may engage third-party service providers to assist us in providing and
                            improving our services. These providers may have access to your information but are obligated to
                            maintain its confidentiality.</li>
                    </ul>
                </div>
                <div class="info_container">
                    <h4>5. Security</h4>
                    <p>We take reasonable measures to protect your personal information from unauthorized access,
                        disclosure, or alteration. </p>
                </div>
                <div class="info_container">
                    <h4>6. Your Choices</h4>
                    <p>You can access, update, or delete your personal information through the app's settings. You can also
                        contact us to request access or deletion of your data.</p>
                </div>
                <div class="info_container">
                    <h4>7. Changes to this Privacy Policy</h4>
                    <p>We may update this privacy policy from time to time to reflect changes in our practices or for other
                        operational, legal, or regulatory reasons. We will provide notice of any material changes.</p>
                </div>
                <div class="info_container">
                    <h4>8. Contact Us</h4>
                    <p>
                        If you have questions or concerns about this privacy policy or our data practices, please contact us
                        at <a href="mailto:privacy@teddyed.tech">privacy@teddyed.tech</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavBar from './NavBar.vue';

export default {
    components: { NavBar }
}
</script>

<style  scoped>
.content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    gap: 30px;
    font-size: 17px;
    font-style: normal;
    line-height: 143.52%;
    font-family: nunitoMedium;
}

.heroHeader {
    color: #33357D;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 170%;
    text-align: center;
    color: #33357D;
    font-family: nunitoBlack;
}

.privacy_container {
    width: 85%;
    margin: 0 0 100px;
}

h4 {
    font-family: nunitoExtrabold;
    margin-bottom: 10px;
}

ul li {
    margin-bottom: 10px;
}

.info_container {
    margin-bottom: 30px;
}
</style>